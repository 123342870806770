<template>
  <v-dialog v-model="dialog.display" :max-width="dialog.maxWidth || '300'">
    <v-card class="py-3">
      <v-card-title v-if="dialog.title">
        <span class="text-h6 font-weight-medium">{{ dialog.title }}</span>
      </v-card-title>
      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="confirm()"
        >{{ (dialog.confirm && dialog.confirm.yes) || $i18n.t('btn.yes') }}</v-btn>
        <v-btn text @click="cancel()">{{ (dialog.confirm && dialog.confirm.yes) || $i18n.t('btn.no') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {
          title: null,
          display: false,
          maxWidth: null,
          confirm: {
            yes: null,
            no: null
          }
        };
      }
    }
  },
  data: () => ({}),
  methods: {
    confirm() {
      this.dialog.display = false;
      this.$emit("confirmed");
    },
    cancel() {
      this.dialog.display = false;
      this.$emit("cancelled");
    }
  }
};
</script>