<template>
  <v-dialog v-model="dialog.display" max-width="500">
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-medium">{{ dialog.title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4 px-6">
        <v-text-field
          :label="$i18n.t('permission.role.form.fields.name.title')"
          :placeholder="$i18n.t('permission.role.form.fields.name.placeholder')"
          v-model="form.name"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
          :error-messages="nameErrors"
          :disabled="dialog.update"
        ></v-text-field>
        <v-select
          :label="$i18n.t('permission.role.form.fields.scope.title')"
          :placeholder="
            $i18n.t('permission.role.form.fields.scope.placeholder')
          "
          v-model="form.scope"
          :items="scopeItems"
          @input="$v.form.scope.$touch()"
          @blur="$v.form.scope.$touch()"
          :error-messages="scopeErrors"
        ></v-select>
        <v-textarea
          :label="$i18n.t('permission.role.form.fields.description.title')"
          :placeholder="
            $i18n.t('permission.role.form.fields.description.placeholder')
          "
          rows="2"
          no-resize
          v-model="form.description"
          @input="$v.form.description.$touch()"
          @blur="$v.form.description.$touch()"
          :error-messages="scopeErrors"
        >
        </v-textarea>
        <v-select
          :items="rolesIds"
          :label="$i18n.t('permission.role.form.fields.roleId.title')"
          :placeholder="
            $i18n.t('permission.role.form.fields.roleId.placeholder')
          "
          v-model="form.roleId"
          clearable
          :disabled="dialog.update"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{ $i18n.t("btn.close") }}</v-btn>
        <v-btn text @click="save()" :loading="loading" color="primary">{{
          $i18n.t("btn.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import {
  maxLength,
  requiredUnless,
  required,
  helpers,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

const matchScope = (value) =>
  !helpers.req(value) || Vue.$utils.matchScope(value);

export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          title: null,
          display: false,
          update: false,
        };
      },
    },
    form: {
      type: Object,
      default: function () {
        return {
          name: null,
          scope: null,
          description: null,
          roleId: null,
        };
      },
    },
    roleItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    scopeItems: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: () => ({
    loading: false,
  }),
  validations() {
    const validations = {
      form: {
        name: {
          required: required,
          maxLength: maxLength(30),
        },
        scope: {
          required: requiredUnless("isOptional"),
          matchScope,
        },
        description: {
          maxLength: maxLength(255),
        },
      },
    };
    return validations;
  },
  methods: {
    async save() {
      const url = this.dialog.update ? "roles.update" : "roles.create";
      const message = this.$i18n.t(
        this.dialog.update
          ? "permission.role.form.update.success"
          : "permission.role.form.create.success"
      );

      this.notify({ status: false });
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.notify({ message: this.$i18n.t("form_error") });
      }

      this.loading = true;

      try {
        await this.request({
          url: url,
          method: "POST",
          data: this.form,
          messages: {
            403: true,
            400: true,
            201: message,
            200: message,
            422: (error) => {
              if(error.code === 'E100') {
                return this.$i18n.t("permission.role.form.fields.name.already_exist")
              }else{
                return this.$i18n.t('error_occured')
              }
            },
            500: true
          }
        });
        this.$emit('saved');
        // reset form validation
        this.$v.$reset();
        // hide dialog
        this.dialog.display = false;
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  computed: {
    ...mapGetters({ scopesItems: "permission/scopes" }),
    rolesIds() {
      if (!this.form.scope) return [];
      return this.roleItems.filter((role) => role.scope === this.form.scope);
    },
    isOptional() {
      return this.dialog.update;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.form.name.$dirty) return errors;

      !this.$v.form.name.required &&
        errors.push(this.$i18n.t("permission.role.form.fields.name.required"));
      !this.$v.form.name.maxLength &&
        errors.push(
          this.$i18n.t("permission.role.form.fields.name.max_length", {
            max: this.$v.form.name.$params.maxLength.max,
          })
        );

      return errors;
    },
    scopeErrors() {
      const errors = [];

      if (!this.$v.form.scope.$dirty) return errors;

      !this.$v.form.scope.required &&
        errors.push(this.$i18n.t("permission.role.form.fields.scope.required"));
      !this.$v.form.scope.matchScope &&
        errors.push(this.$i18n.t("permission.role.form.fields.scope.invalid"));

      return errors;
    },
    description() {
      const errors = [];

      if (!this.$v.form.description.$dirty) return errors;

      !this.$v.form.description.maxLength &&
        errors.push(
          this.$i18n.t("permission.role.form.fields.description.max_length", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );

      return errors;
    },
  },
};
</script>