<template>
  <div>
    <permission></permission>
  </div>
</template>

<script>
import Permission from './../../components/permissions/permission';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('permission.title')
    }
  },
  components: {
    Permission
  }
}
</script>