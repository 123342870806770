<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">{{ $i18n.t("permission.title") }}</div>
    <v-card class="mb-4" rounded="5">
      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addRole()" v-if="$permission.$can('role.create', ['user'])">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $i18n.t("permission.role.btn.add_role") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-text class="pb-0 px-5">
        <div
          class="text-subtitle-1 font-weight-bold secondary-font mb-0"
        >{{ $i18n.t("permission.manage.title") }}</div>
        <div class="grey--text text-body-1 text--darken-1">{{ $i18n.t("permission.manage.description") }}</div>
        <div class="d-flex flex-wrap justify-end my-7">
          <v-select
            :items="scopes"
            :label="$i18n.t('permission.role.form.fields.scope.placeholder')"
            v-model="form.scope"
            class="mr-3 font-weight-bold"
            style="max-width: 220px"
            append-icon="mdi-shield-lock-outline"
          ></v-select>
          <v-select
            :label="$i18n.t('permission.role.form.fields.roleId.title2')"
            v-model="form.roleId"
            class="mb-0 mr-3 w200 font-weight-bold"
            :items="roleItems"
            @change="changeRoleId"
            return-object
            style="max-width: 200px"
            append-icon="mdi-shield-account-outline"
          ></v-select>
          <v-btn
            :disabled="!form.roleId || (form.roleId && form.roleId.protected)"
            text
            color="primary"
            class="mt-4"
            @click="editRole()"
             v-if="$permission.$can('role.update', ['user'])"
          >{{ $i18n.t("permission.role.btn.edit_role") }}</v-btn>
          <v-btn
            :disabled="!form.roleId || (form.roleId && form.roleId.protected)"
            text
            color="red"
            class="mt-4"
            :loading="loading.remove"
            @click="removeRole()"
             v-if="$permission.$can('role.delete', ['user'])"
          >{{ $i18n.t("permission.role.btn.remove_role") }}</v-btn>
        </div>
      </v-card-text>
      <template v-if="form.roleId && form.scope">
        <div v-if="loading.permission">
          <div class="text-center my-5">
            <v-progress-circular indeterminate color="secondary" width="4" size="60"></v-progress-circular>
          </div>
        </div>
        <div v-else>
          <v-card-text class="pt-0">
            <v-expansion-panels v-if="$permission.$can('permission.get', ['user'])" flat focusable multiple v-model="panel">
              <v-expansion-panel disabled v-for="(resource, k) in resources" :key="k">
                <v-expansion-panel-header
                  color="grey lighten-5"
                  class="font-weight-bold grey--text text--darken-4"
                >
                  {{
                  $i18n.t("permission.manage.form.fields.resourceType." + k)
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="py-2">
                  <div class="d-flex flex-wrap">
                    <v-checkbox
                      class="ml-2"
                      v-model="form.permission"
                      :label="r.description"
                      :value="r.resourceId"
                      v-for="r in resource"
                      :key="r.resourceId"
                      color="success"
                    ></v-checkbox>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions v-if="$permission.$can('permission.update', ['user'])">
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading.update"
              class="mr-2"
              color="primary"
              @click="updatePermissions()"
              :disabled="form.roleId.protected"
            >{{ $i18n.t("btn.update") }}</v-btn>
          </v-card-actions>
        </div>
      </template>
    </v-card>
    <role-form
      :form="roleForm"
      :roleItems="roles"
      @saved="roleSaved"
      :dialog="roleFormDialog"
      :scope-items="scopes"
      v-if="$permission.$can('role.create', ['user']) || $permission.$can('role.update', ['user'])"
    ></role-form>
    <role-remove :dialog="roleRemoveDialog" @confirmed="removeRoleConfirm">
      <template v-slot:content>
        <div class="text-body-1">{{ $i18n.t('permission.role.confirm_delete') }}</div>
      </template>
    </role-remove>
  </div>
</template>

<script>
import RoleForm from "./role-form";
import RoleRemove from "./../utils/confirm";
import { mapActions, mapGetters } from "vuex";

export default {
  created() {
    this.setRoles();
    this.setScopes();
    this.setResources();
  },
  data: () => ({
    roleFormDialog: {
      title: null,
      display: false,
      update: false
    },
    roleForm: {
      name: null,
      description: null,
      scope: null,
      roleId: null
    },
    roleRemoveDialog: {
      display: false
    },
    form: {
      scope: null,
      roleId: null,
      permission: []
    },
    roles: [],
    scopes: [],
    permissions: [],
    resources: {},
    loading: {
      permission: false,
      update: false,
      remove: false
    },
    panel: []
  }),
  methods: {
    setScopes() {
      for (const scope of this.scopesItems) {
        this.scopes.push({
          value: scope.value,
          text: this.$i18n.t(scope.text)
        });
      }
    },
    async setRoles() {
      const response = await this.request({
        url: "roles.list"
      });
      this.roles = [];
      for (const role of response.data.data) {
        this.roles.push({
          text: role.name,
          value: role.name,
          scope: role.scope,
          protected: role.protected,
          description: role.description
        });
      }
    },
    async setResources() {
      const resources = {};
      const response = await this.request({ url: "resources.list" });
      let panel = 0;
      for (const r of response.data) {
        if (!resources[r.type]) {
          resources[r.type] = [];
          this.panel.push(panel++);
        }

        resources[r.type].push({
          resourceId: r.name,
          description: r.description
        });
      }
      this.resources = resources;
    },
    async changeRoleId(roleId) {
      if (!roleId.value) return;

      this.form.permission = [];
      this.notify({ status: false });
      this.loading.permission = true;
      try {
        const response = await this.request({
          url: "permissions.get",
          params: {
            roleId: roleId.value
          },
          messages: {
            404: this.$i18n.t('permission.role.not_found'),
            500: true,
            403: true
          }
        });
        for (const permission of response.data) {
          this.form.permission.push(permission.resourceId);
        }
      } catch (error) {
       // empty
      }
      this.loading.permission = false;
    },
    addRole() {
      this.roleFormDialog = {
        title: this.$i18n.t("permission.role.form.title.add"),
        display: true,
        update: false
      };
      this.roleForm = {
        name: null,
        scope: null,
        description: null,
        roleId: null
      };
    },
    editRole() {
      if (!this.form.roleId) return;

      this.roleForm = {
        name: this.form.roleId.value,
        description: this.form.roleId.description,
        scope: this.form.roleId.scope
      };
      this.roleFormDialog = {
        title: this.$i18n.t("permission.role.form.title.edit"),
        display: true,
        update: true
      };
    },
    removeRole() {
      this.roleRemoveDialog.display = true;
    },
    async removeRoleConfirm() {
      if (!this.form.roleId) return;
      if(this.form.roleId.protected) return;

      this.loading.remove = true;
      try {
        await this.request({
          url: 'roles.delete',
          method: 'POST',
          data: {
            roleId: this.form.roleId.value
          },
          messages: {
            204: this.$i18n.t('permission.role.form.delete.success'),
            403: true,
            500: true,
            400: true,
            422: this.$i18n.t('permission.role.is_protected')
          }
        });
        // reload roles
        this.form.roleId = null;
        this.setRoles();
      } catch (error) {
        // empty
      }
      this.loading.remove = false;
    },
    roleSaved() {
      // reload roles
      this.setRoles();
    },
    async updatePermissions() {
      const self = this;
      this.notify({ status: false });

      if (!this.form.roleId) return;

      this.loading.update = true;

      const data = {
        roleId: this.form.roleId.value,
        resources: []
      };
      this._.each(this.resources, resource => {
        for (const r of resource) {
          data.resources.push({
            resourceId: r.resourceId,
            allow: self.form.permission.includes(r.resourceId) ? true : false
          });
        }
      });

      try {
        await this.request({
          url: "permissions.update",
          method: "POST",
          data: data,
          messages: {
            403: true,
            404: self.$i18n.t("permission.role.not_found"),
            500: true,
            422: self.$i18n.t("permission.role.is_protected")
          }
        });
        this.notify({
          message: this.$i18n.t("permission.manage.form.update.success")
        });
      } catch (error) {
        // empty
      }
      this.loading.update = false;
    },
    ...mapActions({ notify: "notification/notify", request: "request" })
  },
  computed: {
    ...mapGetters({ scopesItems: "permission/scopes" }),
    roleItems() {
      if (!this.form.scope) {
        return [];
      }

      return this.roles.filter(role => role.scope === this.form.scope);
    }
  },
  watch: {
    "form.scope"() {
      this.form.roleId = null;
    }
  },
  components: {
    RoleForm,
    RoleRemove
  }
};
</script>